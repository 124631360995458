import React, { Component } from 'react'
import '../styles/scss/terms.scss'
import '../styles/scss/news.scss'

export default class FAQ extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: {
                name: 'FAQ',
                title: 'Frequently Asked Questions',
                date: 'Last updated February 23, 2023',
                gallery: [
                    {
                        title: 'Who are we?',
                        content: `
                        Verse Estate is a VR technology company headquartered in Dubai providing metaverse solutions (consumer metaverse / enterprise metaverse / industrial metaverse). Unlike gaming metaverses, Verse Estate is focused on hyper realistic.
Where are we based? 
We recently opened our high tech 6000 sq ft. showroom at the heart of Dubai in Al Serkal Avenue.
                        `
                    },
                    {
                        title: 'What is Verse World?',
                        content: `
                        Verse World is a full Virtual Reality World, with its own marketplace and virtual economy. It is going to be the most realistic metaverse on earth.
                        `
                    },
                    {
                        title: 'What are our services?',
                        content: `
                        At Verse Estate we offer many services, from scanning, modeling, gaming to podcast or even motion capture. All the services are explained in detail on our website.                         `
                    },
                    {
                        title: 'Can you come and visit our showroom?',
                        content: `
                        Sure. The Showroom is open from Monday to Saturday 9am to 18pm. Anyone who want to discover virtual reality is welcome.
                        `

                    },
                ]
            },
        }
    }

    renderTerms = () => {
        if (this.state.page.gallery) 
            return this.state.page.gallery.map((element, index) => {
                return (
                    <p key={index} id={element?.title}>
                        {index + 1}. {element?.title}
                        <br/>
                        {element?.content}
                    </p>
                )
            })
    }

    renderTable = () => {
        if (this.state.page.gallery)
            return (
                <>
                    <h3 style={{fontSize: 22}}>List of questions - FAQs</h3>
                    <div className='table-content'>
                        {
                            this.state.page.gallery.map((element, index) => {
                                return (
                                    <>
                                        <a href={'#'+element?.title} key={index}>
                                            {index + 1}. {element?.title}
                                        </a>
                                    </>
                                )
                            })
                        }
                    </div>
                    
                </>
            )
    }

    render() {
        let {page} = this.state
        return (
            <div className='news article-page terms'>
                <div className='article-container'>
                    <div className='titles'>
                        <h3>{page?.title}</h3>
                        <h4>{page?.date}</h4>
                        <p style={{fontWeight: 500}}>{page?.subtitle}</p>
                        <p>{page?.content}</p>
                        {this.renderTable()}
                        {this.renderTerms()}
                        <br/>
                        <br/>
                    </div>

                </div>
            </div>
        )
    }
}
