import React, { Component } from 'react'
import Footer from '../components/Common/Footer'
import Header from '../components/Common/Header'
import SEO from '../components/Common/SEO'
import FAQ from '../components/FAQ'
import Layout from '../layouts/Layout'

export default class faq extends Component {
    render() {
        return (

            <div id="scrollable" className='no-snap'>
                <Header isTransparent={false} isLoaded={true} threshold={0.01} />
                <SEO
                    title='FAQs'
                />
                    <div className='snap'>
                        <FAQ/>
                    </div>
                <Footer />
            </div>
        )
    }
}
